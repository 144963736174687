.temp_layut {
	height: 100%;
	width: 100%;
	background-color: #fea876;
}
.temp_head { 
	padding: 0 !important;
	background-color: rgb(255, 255, 253);
}
/* ::content */
.temp_content {
	padding: 1rem 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
}
.temp_cont_head {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, auto);
	justify-content: center;
	align-items: center;
	grid-column-gap: 20px;
	padding: 0.5rem;
}
.temp_maincont_card_head {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 15px;
}
.temp_maincont_card_tit {
	font-size: 1.2rem;
	color: #242323;
	white-space: normal;
	overflow: hidden;
}

/* ::content */

.temp_modal_head {
	margin: 0;
	padding: 0;
	background-color: rgb(218, 226, 226);
}
.temp_foot {
	background-color: darkgrey;
	display: flex;
	justify-content: space-between;
}

/* card
 */

.addbtn {
	justify-content: center;
	align-items: center;
	display: flex;
}
.exportBtn{
	color: red; 
	box-sizing: border-box;
}
/* .exportBtn:hover{ 
} */

.temp_cont_head .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	min-width: 200px;
	height: 35px;
	border-radius: 20px; 
	color: rgb(0, 0, 0);
	font-size: 1rem;
	text-align: center;
	align-items: center;
}

.temp_cat_btn {
	width: 160px;
	height: content; 
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* modal  */
.temp_modal_cont_box {
	display: flex;
	justify-content: space-between;
	width: 100%;
	position: relative;
}
.temp_modal_cont {
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	padding: 0 1rem;
	position: relative;
}

.temp_card {
	width: 100%;
	height: fit-content;
	background: radial-gradient(#76b2fe, #b69efe);
	border-radius: 12px;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
	color: #120338;
	font-size: 1rem;
	letter-spacing: 1px;
	margin-bottom: 5px;
}
.template_card__hover{
	text-align: center; 
	font-size: 35px;
	line-height: 40px;
	padding: 45px 50px;
	border-radius: 10px;
	border: 1px dashed rgb(219, 178, 185); 
	width: 100%;
	justify-content: center;
}  
.template_card__hover button{
	margin: 0 10px 0;
}
.temp_layut .ant-dropdown-arrow{
	background-color: red;
}
.temp_mod_card_head {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 15px;
}
.temp_mod_card_tit {
	font-size: 1.2rem;
	color: #242323;
	white-space: normal;
	overflow: hidden;
}

/* side  */
.temp_modal_side_box {
	width: 35%;
	height: 100%;
	display: flex;
	flex-direction: column; 
	justify-content: space-between;
	position: relative;
	padding: 0 15px; 
}
.temp_mod_side_top{
	overflow: auto;
}
.temp_side_card {
	width: 100%;
	background: radial-gradient(#76b2fe, #b69efe);
	border-radius: 12px;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
	color: #120338;
	font-size: 1.1rem;
	letter-spacing: 1px;
	height: fit-content;
	margin-bottom: 5px;
}

.temp_side_card_head {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 15px;
}
.temp_side_card_tit {
	font-size: 1rem;
	color: #242323;
	white-space: normal;
	overflow: hidden;
}

.temp_mod_foot {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	overflow: hidden;
}
/* modal  */

.dragging {
	background: rgba(0, 0, 0, 0.25);
}
.added {
	background: rgb(228, 169, 7);
	color: black;
} 
