.content_section .ant-upload-list {
  display: none;
}
.category-section .ant-upload-list {
  display: none;
}
.dictionary_section .ant-upload-list {
  display: none;
}
.content_flex__div {
  display: flex;
}
.content_flex__box1 {
  width: 50%;
  border-right: 1px solid black;
  padding-right: 30px;
  min-height: 60vh;
  box-sizing: border-box;
}
.content_flex__box2 {
  width: 50%;
  padding-left: 30px;
  min-height: 60vh;
  box-sizing: border-box;
}
