* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.main-layout {
  min-height: 100vh;
}
.main-layout-trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.main-layout-trigger:hover {
  color: #1890ff;
}

.main-layout .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.question-icon-div {
  float: right;
  font-size: 25px;
  margin-right: 45px;
  position: relative;
}
.question-count {
  position: absolute;
  top: -20px;
  right: -20px;
  color: red;
  border-radius: 50%;
  padding: 5px;
  height: 20px;
}
.z-modal {
  z-index: 20 !important;
}
.spinner {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 2222222222;
}

/* symptom category */

.loginP {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-button {
  float: right;
}
.display-none {
  display: none !important;
}
.ant-layout-content {
  overflow: auto;
}
.category-section {
  min-width: 1050px !important;
}
.illness {
  min-width: 1000px !important;
}
.shablon-form {
  min-width: 1200px !important;
}
.region_section {
  min-width: 500px !important;
}
.clinics_section {
  min-width: 900px !important;
}
.question-section {
  min-width: 1000px !important;
}
.contact-section {
  min-width: 600px !important;
}
.filter_section {
  min-width: 500px !important;
}
.ant-layout-sider {
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
}
.ant-layout-sider::-webkit-scrollbar {
  width: 5px;
}
.ant-layout-sider::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.ant-layout-sider::-webkit-scrollbar-thumb {
  background: rgb(49, 124, 194);
  border-radius: 10px;
}
.site-layout {
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
}
.ant-layout-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.ant-layout-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(224, 220, 220);
  border-radius: 10px;
}
.ant-layout-content::-webkit-scrollbar-thumb {
  background: rgb(49, 124, 194);
  border-radius: 10px;
}
