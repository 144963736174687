.ck-editor__main div {
  min-height: 30vh;
}
.info-row {
  display: flex;
  justify-content: space-around;
}
.info-row div {
  width: 45%;
}
.info-row div input {
  width: 100%;
}
.contact-section input {
  margin-top: 4px;
}
