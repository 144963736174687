.illness-modal .ck-editor__main div {
  min-height: 30vh;
}
.image-upload .ant-tooltip-content {
  display: none;
}
.display-none {
  display: none !important;
}
.illness-info {
  display: flex;
}
.illness-image {
  width: 400px;
  margin-right: 20px;
}
.illness-image h2 {
  text-align: center;
  padding: 10px;
}
.illness-image img {
  width: 100%;
}
.illness-info-modal .ant-modal-footer button:first-child {
  display: none;
}
