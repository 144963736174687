.dashboard-moderator{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}
.dashboard-left , .order-confirm{
    min-height: 100vh;
    flex: 1 1 auto;
    border-radius: 15px;
}
.order-confirm{
    background-color: #fdfdfd;
    box-shadow: rgba(100,100,111,.2) 0px 7px 29px 0px;
    width: 240px !important;
    position: sticky;
    top: 0;
    right: 0;
    padding: 10px;  
}
.countdown{ 
    border-radius: 4px; 
    min-width: 100px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    font-size: 16px;
}

.order-buttons{
    display: flex;
    gap: 10px;
}

.countdown_button{
    width: 90px;
    background-color: white;
    text-align: center;
    border-radius: 15px; 
}
.check_Order__Button{
    height: 38px !important;
    border-radius: 15px !important;
    font-size: 20px !important;
}


.dashboard-left{
    margin-right: 20px;
    width: calc(100% - 240px);
}
.comments-confirm, .payment-confirm{
    flex: 1 1 auto;
    background-color: #fdfdfd;
    box-shadow: rgba(100,100,111,.2) 0px 7px 29px 0px;
    border-radius: 15px;
    padding: 10px;
}
.payment-confirm{
    min-height: 50vh;
}
.comments-confirm{
    margin-bottom: 20px;
    padding-bottom: 20px;
    min-height: 50vh;
}
.order-card{
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #4D5C6A;
    border-radius: 10px;
    color: white;
    margin-bottom: 20px;
    min-height: 100px;
}
.order-card h3{
    color: white;
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
}
.order-card p{
    font-size: 18px;
    margin-bottom: 10px;
}
.warning-countdown{
    background-color: #FF4D4F;
    color: white;
    border: 1px solid transparent;
}
.noZakaz{
    width: 100%;
    height: 120px;
    padding: 10px;
    background: #E55D87;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #5FC3E4, #E55D87);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #5FC3E4, #E55D87); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   
    color: black;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}