ul {
  list-style: none;
}
.main-categories {
  border-bottom: 2px solid rgb(46, 21, 21);
  display: flex;
  justify-content: center;
  /* align-items:flex-end; */
}
.main-categories ul {
  display: flex;
  margin-bottom: 0;
}
.category-main-button {
  /* background-color: rgb(216, 27, 27); */
  height: 40px !important;
  width: 50px;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .category-main-button:hover{
    background-color: red;
} */
.category-main-plus {
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgb(216, 27, 27); */
  /* color: rgb(216, 27, 27); */
  transition: 0.4s;
}
.category-main-plus:hover {
  color: white;
  /* background: red; */
  /* border: 1px solid red; */
}
.category-main-edit {
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  /* border: 1px solid #F4CA16; */
  /* color: #F4CA16; */
  transition: 0.4s;
}
.category-main-edit:hover {
  color: white;
  /* background: #F4CA16; */
  /* border: 1px solid #F4CA16; */
}
.category-main-delete {
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  /* border: 1px solid #47BC2C; */
  /* color: #47BC2C; */
  transition: 0.4s;
}
.category-main-delete:hover {
  color: white;
  /* background: #47BC2C; */
  /* border: 1px solid #47BC2C; */
}
.main-categories-content {
  width: 100%;
  display: flex;
}

.categories-service {
  width: 60%;
  border-left: 1px solid rgb(138, 130, 130);
  padding-left: 5px;
}
.categories-subcategory {
  width: 40%;
  padding-right: 5px;
}
.cate-button-icon {
  font-size: 20px;
  cursor: pointer;
}
.cate-icon-delete {
  /* color: #47BC2C; */
  transition: 0.4s;
}
.cate-icon-edit {
  /* color: #F4CA16; */
  transition: 0.4s;
  margin-left: 15px;
}
.info-div-space {
  height: 15px;
}
.category-section .ant-tabs-tab-active {
  background-color: rgb(106, 181, 231) !important;
}
.category-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  text-shadow: 0 0 0.25px currentColor;
}
.category_upload__buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
