.doctors-head{
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    min-height: 10vh;
    padding: 20px; 
}
.doctor-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
}
.doctor-btns button{
    padding: 2px 12px;
    border-radius: 4px;
}
.tool-btns{
    float: left;
}
.btn-none{
    visibility: hidden;
} 
.clinic_modal__location{
    display: flex;
}
.clinic_modal__region{
    width: 50%;
    height: 100%;
    padding-right: 20px;
}
.clinic_modal__district{
    width: 50%;
}
.info_images__div{
    display: flex;  
}
.info_img__wrap{
    width: 104px;
    height: 104px;
    margin: 0 8px 0px 0;
    vertical-align: center;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    position: relative;
}
.info_img__wrap:hover .info_img__up{
    opacity: 1;
}
.info_img{ 
    width: 88px;
    height: 88px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.info_img img{
    width: 100%;
}
.info_img__up{
    width: 88px;
    height: 88px;
    cursor: pointer;
    opacity: 0;
    z-index: 3;
    color: white;
    font-size: 18px;
    transition: 0.3s;
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
} 
.clinics_section .ant-upload-list-picture-card-container{
    display: none !important;
    margin: 0;
    width: 0;
    height: 0;
}