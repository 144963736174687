.question-section .ant-collapse-header{
    display: flex !important;
}
.question-section .ant-collapse-arrow{
    padding-top: 5px;
}
.question-heart{
    transition: 0.3s;
}
.question-heart:hover{
    color: #F73542;
    color: #575050;
}
