.region_left{
    padding-right: 5px;
}
.region_right{
    border-left: 1px solid rgb(138, 130, 130);
    padding-left: 5px;
}

.ant-table .ant-table-container .ant-table-content table tbody tr td button:active{
        background-color: red;
}