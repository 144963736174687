* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto;
}
.essential_container_login_page {
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.essential_row {
  border-radius: 20px;
  padding: 30px;
  border: 1px solid #e7dee9;
  background-color: #e7dee9;
  align-self: center;
  width: 50%;
  height: 50%;
  position: relative;
  box-shadow: 2px 2px 10px rgb(120, 100, 100);
}
.login_input {
  padding: 8px !important;
  border-radius: 11px !important;
  background-color: white !important;
}
.login_avatar_div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
  top: -40px;
}
.login_title {
  text-align: center;
}
.login_avatar {
  background-color: #4998af !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.essential_container_login_page input:-internal-autofill-selected {
  background-color: white !important;
}
@media screen and (max-width: 640px) {
  .essential_container_login_page {
    width: 95%;
  }
  .login_title {
    font-size: 16px !important;
  }
  .essential_row {
    width: 90%;
  }
}
@media screen and (min-width: 820px) and (max-width: 1180px) {
  .essential_container_login_page {
    width: 75%;
  }
  .login_title {
    font-size: 26px !important;
  }
  .essential_row {
    width: 80%;
    height: 40%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .essential_container_login_page {
    width: 75%;
  }
  .login_title {
    font-size: 26px !important;
  }
  .essential_row {
    width: 80%;
    height: 40%;
  }
}
